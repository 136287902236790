html,
body,
#root,
.App {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  font-size: 15px;
  -webkit-overflow-scrolling: touch;
}

a,
a:hover {
  color: inherit;
  text-decoration: inherit;
}

.lightsaber {
  transition: all 5s ease-out;
}

.star.starred path {
  fill: white;
}

.star:hover:not(.starred) path {
  fill: rgba(255, 255, 255);
}

code {
  font-family: 'Menlo', monospace;
}

b {
  font-weight: 700;
}

svg.icon {
  width: 30px;
  color: white;
}

span.link:hover {
  text-decoration: underline;
}

.tab {
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  display: inline-block;
  color: #fff;
  padding: 2px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  width: 30px;
  border-radius: 2000px;
  text-align: center;
}

.tab.disabled {
  cursor: default;
  color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.tab:not(.disabled) {
  transition: all 200ms ease-out;
}

.tab:not(.disabled):hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.4);
  border-color: #fff;
}

p,
ul {
  color: #cad0d8;
}

.tab.active,
.tab.active:hover {
  background-color: #ffffff;
  color: #000000;
  border-color: white;
}

.SplitPane,
.Pane1.vertical,
.Pane1.horizontal {
  overflow: visible !important;
}

@media (max-width: 992px) {
  #leaderboard table {
    margin-left: 0 !important;
  }

  #leaderboard h5 {
    text-align: left !important;
  }
}
